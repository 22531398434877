import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import Hero from '../components/Hero'

/**
 * Create Page Template Component
 * 
 * @param {string} [footerCTAText]
 * @param {string} [footerCTALink]
 * @param {boolean} [footerDimmed=false]
 */
const PageTemplate = props => {
  return (
    <Layout
      className={`is-page-${props.pageIdentifier || 'unlabelled'} ${props.pageClass || ''}`}
      title={props.title}
      seoTitle={props.seoTitle}
      seoDescription={props.seoDescription}
      heroImage={props.image}
      footerCTAText={props.footerCTAText}
      footerCTALink={props.footerCTALink}
      footerDimmed={props.footerDimmed}
    >
      <Hero
        title={props.title}
        subtitle={props.subtitle}
        image={props.image}
        showCTA={props.showCTA}
        anchor={props.imageAnchor}
        imageAlt={props.imageAlt || props.title}
        aboveTitle={props.aboveTitle}
        CTAUrl={props.CTAUrl}
        CTAText={props.CTAText}
      />
      <div className="container content is-narrow">
        {/* Markdown fallback for CMS Previews */}

        {(() => {
          // If the element is used as <PageTemplate>...</PageTemplate> instead of <PageTemplate />, show children
          if (props.children) {
            return props.children
          }
          // If disableMdx is set, return normal body (for CMS Previews)
          else if (props.disableMdx === true || props.disableMdx === 'true') {
            return props.body
          }
          // Otherwise return the MDX Rendered body
          else if (props.body && props.body.length) {
            return <MDXRenderer>{props.body}</MDXRenderer>
          } else {
            return false
          }
        })()}
      </div>
    </Layout>
  )
}

export { PageTemplate }

/**
 * Fill Page Component with GraphQL Data
 */
const Page = ({ data }) => {
  // Split imageAnchor classes when multiple
  const formattedImageAnchor = data.mdx.frontmatter.imageAnchor
    ? data.mdx.frontmatter.imageAnchor.toString().replace(',', ' ')
    : ''

  return (
    <PageTemplate
      title={data.mdx.frontmatter.title}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      seoTitle={data.mdx.frontmatter.seoTitle || ''}
      seoDescription={data.mdx.frontmatter.seoDescription | ''}
      pageClass={data.mdx.frontmatter.pageClass}
      subtitle={data.mdx.frontmatter.subtitle}
      image={data.mdx.fields.image}
      imageAlt={data.mdx.frontmatter.imageAlt}
      showCTA={data.mdx.frontmatter.showCTA}
      body={data.mdx.body}
      imageAnchor={formattedImageAnchor || ''}
      aboveTitle={data.mdx.frontmatter.aboveTitle}
      CTAUrl={data.mdx.frontmatter.CTAUrl}
      CTAText={data.mdx.frontmatter.CTAText}
    />
  )
}

export default Page

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        pageClass
        templateKey
        showCTA
        subtitle
        seoTitle
        seoDescription
        imageAnchor
        imageAlt
        aboveTitle
        CTAUrl
        CTAText
      }
      fields {
        pageIdentifier
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
